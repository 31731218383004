'use client'

import { LocalizedLink } from '@astrolab/web/common/LocalizedLink'
import { Box, Button, Flex, Icon, SimpleGrid, Text } from '@chakra-ui/react'
import { useLocale, useTranslations } from 'next-intl'
import { useEffect, useRef, useState } from 'react'
import { MdChevronRight } from 'react-icons/md'

interface AnnouncementProps {
  date: string
  href: string
  discount: string
  code: string
  [rest: string]: any
}

const Announcement: React.FC<AnnouncementProps> = ({
  date,
  href,
  discount,
  code,
  ...rest
}) => {
  const locale = useLocale()
  const t = useTranslations('announcement')

  const [timerDays, setTimerDays] = useState('00')
  const [timerHours, setTimerHours] = useState('00')
  const [timerMinutes, setTimerMinutes] = useState('00')
  const [timerSeconds, setTimerSeconds] = useState('00')

  const textColor = 'white'
  const interval = useRef<any | null>(null)
  const startTimer = () => {
    const countdownDate: Date = new Date(Date.parse(date))

    interval.current = setInterval(() => {
      const now = new Date().getTime()
      const distance = countdownDate.getTime() - now

      let days = Math.floor(distance / (1000 * 60 * 60 * 24))
      let hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      )
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      let seconds = Math.floor((distance % (1000 * 60)) / 1000)

      const daysStr = days < 10 ? `0${days}`: `${days}`
      const hoursStr = hours < 10 ? `0${hours}`: `${hours}`
      const minutesStr = minutes < 10 ? `0${minutes}`: `${minutes}`
      const secondsStr = seconds < 10 ? `0${seconds}`: `${seconds}`

      if (distance < 0) {
        if (interval.current) clearInterval(interval.current)
      } else {
        setTimerDays(daysStr)
        setTimerHours(hoursStr)
        setTimerMinutes(minutesStr)
        setTimerSeconds(secondsStr)
      }
    }, 1000)
  }

  useEffect(() => {
    startTimer()
    return () => {
      if (interval.current) clearInterval(interval.current)
    }
  }, [date])

  return (
    <Flex
      px={{
        base: '0px',
        xl: 'unset',
      }}
      bg="radial-gradient(127.15% 100% at 47.42% 0%, #B4B0FE 0%, #363285 22.92%, #110D5B 42.71%, #050327 88.54%)"
      w="100%"
      bgPosition="center"
      h={{ base: '90px', lg: '90px' }}
      bgSize="cover"
      justify={'center'}
      {...rest}
    >
      <SimpleGrid
        display={{ base: 'none', md: 'flex' }}
        maxW="max-content"
        columns={{ base: 2, md: 4 }}
        gap="10px"
        w={{ base: '335px', md: '510px', lg: '100%' }}
        my="auto"
      >
        <Flex
          direction="column"
          p={'8px'}
          maxW="60px"
          maxH="60px"
          textAlign="center"
          border="1px solid"
          borderColor="whiteAlpha.300"
          borderRadius="10px"
        >
          <Text
            fontSize={{ base: '20px', md: '26px' }}
            color={textColor}
            lineHeight={'100%'}
            mb="4px"
            fontWeight="800"
          >
            {timerDays}
          </Text>
          <Text
            fontSize={{ base: '6px', md: '8px' }}
            fontWeight="700"
            letterSpacing="0px"
            w="100%"
            color={textColor}
          >
            {t('days')}
          </Text>
        </Flex>
        <Flex
          direction="column"
          p={'8px'}
          maxW="60px"
          maxH="60px"
          textAlign="center"
          border="1px solid"
          borderColor="whiteAlpha.300"
          borderRadius="10px"
        >
          <Text
            fontSize={{ base: '20px', md: '26px' }}
            color={textColor}
            lineHeight={'100%'}
            mb="4px"
            fontWeight="800"
          >
            {timerHours}
          </Text>
          <Text
            fontSize={{ base: '6px', md: '8px' }}
            fontWeight="700"
            letterSpacing="0px"
            w="100%"
            color={textColor}
          >
            {t('hours')}
          </Text>
        </Flex>

        <Flex
          direction="column"
          p={'8px'}
          maxW="60px"
          maxH="60px"
          textAlign="center"
          border="1px solid"
          borderColor="whiteAlpha.300"
          borderRadius="10px"
        >
          <Text
            fontSize={{ base: '20px', md: '26px' }}
            color={textColor}
            lineHeight={'100%'}
            mb="4px"
            fontWeight="800"
          >
            {timerMinutes}
          </Text>
          <Text
            fontSize={{ base: '6px', md: '8px' }}
            fontWeight="700"
            letterSpacing="0px"
            w="100%"
            color={textColor}
          >
            {t('minutes')}
          </Text>
        </Flex>
        <Flex
          direction="column"
          p={'8px'}
          maxW="60px"
          maxH="60px"
          textAlign="center"
          border="1px solid"
          borderColor="whiteAlpha.300"
          borderRadius="10px"
        >
          <Text
            fontSize={{ base: '20px', md: '26px' }}
            color={textColor}
            lineHeight={'100%'}
            mb="4px"
            fontWeight="800"
          >
            {timerSeconds}
          </Text>
          <Text
            fontSize={{ base: '6px', md: '8px' }}
            fontWeight="700"
            letterSpacing="0px"
            w="100%"
            color={textColor}
          >
            {t('seconds')}
          </Text>
        </Flex>
      </SimpleGrid>
      <Flex direction="row" gap={4} my="auto"
        ms={{ base: '0px', lg: '45px', xl: '45px' }}
        me={{ base: '10px', lg: '45px', xl: '45px' }}>
        <Box my="auto">
          <Text
            fontSize={{ base: '26px', md: '32px' }}
            color={textColor}
            lineHeight={'100%'}
            mb="4px"
            fontWeight="800"
          >
            {discount}
          </Text>
        </Box>
        <Flex direction="column">
          <Text
            fontSize="md"
            color={textColor}
            lineHeight={'100%'}
            mb="4px"
            fontWeight="800"
          >
            {t('using-code')}
          </Text>
          <Text
            fontSize={{ base: '20px', md: '26px' }}
            color={textColor}
            lineHeight={'100%'}
            mb="4px"
            fontWeight="800"
          >
            {code}
          </Text>
        </Flex>
      </Flex>
      <Flex my="auto">
        <LocalizedLink locale={locale} href={href} isExternal my="auto">
          <Button
            border="0.6px solid"
            borderColor="rgba(255, 255, 255, 0.25)"
            bg="rgba(255, 255, 255, 0.1)"
            my="auto"
            py="16px"
            px="16px"
            fontSize="sm"
            borderRadius="45px"
            me={{ base: '0px', md: '14px' }}
            w={{ base: '80px', md: '210px' }}
            color="white"
            _hover={{ background: 'rgba(255, 255, 255, 0.18)' }}
            _active={{ background: 'rgba(255, 255, 255, 0.28)' }}
            _focus={{ background: 'rgba(255, 255, 255, 0.18)' }}
            h="46px"
          >
            {t("cta")}<Box as="span" display={{base: "none", md:"flex"}} ms="1">{t("cta-p2")}</Box>
            <Icon as={MdChevronRight} color="white" h="16px" w="16px" />
          </Button>
        </LocalizedLink>
      </Flex>
    </Flex>
  )
}

export default Announcement
